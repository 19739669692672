<template>
  <div class="monitor-main">
        <div class="main-container" @scroll="scrollBar">
            <div class="container-echarts">
                <div v-if="!btnShow"></div>
                <div class="patient-emtry" v-else-if="$store.getters.patientData.length == 0 && !$store.getters.selectPatientId" @click="goAddPatient">
                    <img src="@/assets/img/addpatient-icon.png" alt="">
                    <span class="txt">{{$t("bloodPressure.addpatient")}}</span>
                </div>
                <div class="patient-container" v-else>
                    <p class="patient-info" v-if="patientInfo">
                        <span class="name">{{$t("bloodPressure.patientname")}}：{{patientInfo.name}}</span>
                        <i class="gender-icon"></i>
                        <span class="age">{{$t("bloodPressure.age",{age:patientInfo.age})}}</span>
                    </p>
                    <p class="switch-icon" @click="openSurveyor" v-if="patientInfo">
                        <img src="@/assets/img/switch-icon.png" alt="">
                        <span class="txt">{{$t("bloodPressure.switch")}}</span>
                    </p>
                </div>
                <div class="echarts-tab">
                    <div class="tab-left">
                        <div class="tab-item" :class="{'itemActive':tabAvtive==0}" @click="tabClick(0)">{{$t("bloodPressure.weekly")}}</div>
                        <div class="tab-item" :class="{'itemActive':tabAvtive==1}" @click="tabClick(1)">{{$t("bloodPressure.month")}}</div>
                    </div>
                    <div class="tab-right" :class="{'itemActive':tabAvtive==2}" @click="tabClick(2)">
                        <i></i>
                        <span v-if="!yearType">{{$t("bloodPressure.selectMonth")}}</span>
                        <span v-else>{{(st ? new Date(st).getFullYear()+'-'+((new Date(st).getMonth()+1) < 10 ?'0'+(new Date(st).getMonth()+1):new Date(st).getMonth()+1):'')||'选择月份'}}</span>
                    </div>
                </div>
                <div class="echart-broken" id="echartsLine">

                </div>
            </div>
            <!-- <div class="emtry"></div> -->
            <div class="container-list" >
                <div class="app-btn-container" v-if="isApp" :class="{'disabledbtn':this.$store.getters.patientData.length==0 || !this.patientInfo.id}">
                    <div class="btn-item" @click="GoAdd">{{$t("bloodPressure.ManualEnter")}}</div>
                    <div class="btn-item" @click="goAppAdd">{{$t("bloodPressure.smartDevice")}}</div>
                </div>
                <div class="list-title" ref="listTitle">
                    {{$t("bloodPressure.name")}}
                </div>

                <div class="list-main">
                    <div class="list-item" v-for="(item, index) in monitorList" :key="index">
                        <p class="item-time"><i class="icon"></i><span>{{item.ct}}</span></p>
                        <div class="item-info">
                            <div class="info-details">
                                <div class="details-item">
                                    <p class="title">
                                        {{$t("bloodPressure.xueya")}}
                                    </p>
                                    <p class="txtValue">
                                        {{item.sys}}/{{item.dia}}
                                    </p>
                                </div>
                                <div class="details-item">
                                    <p class="title">
                                        {{$t("bloodPressure.heartrate")}}
                                    </p>
                                    <p class="txtValue">
                                        {{item.heartRate||'--'}}
                                    </p>
                                </div>
                                <div class="details-item">
                                    <p class="title">{{$t("bloodPressure.deviceType")}}</p>
                                    <p class="txtType">{{item.device||'--'}}</p>
                                </div>
                            </div>
                            <div class="item-desc" >
                                <p :class="{'moreTxt':item.moreOpen}" :data-index="index" :ref="'desc'+item.id">{{$t("bloodPressure.Leavemsg")}}：{{item.summary|| $t("bloodPressure.noLeavemsg")}}</p>
                                <van-icon name="arrow-down" class="unfold" v-if="item.moreTxtShow&&!item.moreOpen" @click="setheight(item,1)" />
                                <p class="packup" v-if="item.moreTxtShow&&item.moreOpen" @click="setheight(item,2)" >
                                    <van-icon name="arrow-up" />
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="moreData" v-if="monitorList.length>0" @click="more">{{moreType?$t("bloodPressure.more"):$t("bloodPressure.nomore")}}</div>
                </div>
                <div class="list-empty" v-if="monitorList.length==0">
                    <img src="~@/assets/img/monitor-empty.png" >
                    <p class="txt">{{$t("bloodPressure.nodata")}}</p>
                    <template v-if="btnShow">
                        <p class="btn" v-if="!cusId&&!isApp" @click="GoAdd">{{$t("bloodPressure.promptlyAdd")}}</p>
                    </template>
                </div>
            </div>
        </div>
        <template v-if="btnShow">
            <div class="main-bottom-btn" v-if="!cusId&&monitorList.length>0&&!isApp" @click="GoAdd">
                {{$t("bloodPressure.EntryReacord")}}
            </div>
        </template>
        <van-popup v-model:show="pickerDateShow" position="bottom" >
            <van-datetime-picker
                v-model="currentDate"
                type="year-month"
                @confirm="dateConfirm"
                @cancel="pickerDateShow=false"
                :title='$t("bloodPressure.selectMonth")'
                :min-date="minDate"
                :max-date="maxDate"
            />
        </van-popup>
        <loading v-if="isLoading" msg="加载中..."></loading>
  </div>
</template>

<script>
import * as echarts  from 'echarts'
import { getLocalDateTime } from "@/utils/date";
import Loading from "@/components/ui/Loading";
import { pressurQuery, pressurStat } from "@/api/index"
import VConsole from 'vconsole/dist/vconsole.min.js'
import { patientQuery } from "@/api/patient";

export default {
    components:{
        Loading
    },
    data(){
        return{
            cusId:this.$route.query.cusId,
            isLoading:false,
            moreType:true,
            btnShow:true,
            titleFiexd:false,
            yearType:false,
            monitorList:[],
            tabAvtive:0,
            pickerDateShow:false,
            minDate: new Date(new Date().getFullYear() - 3, 0, 1),
            maxDate: new Date(new Date().getFullYear() , new Date().getMonth()-1, 1),
            currentDate: new Date(),
            st:"",
            et:"",
            isApp:(window.navigator.userAgent.indexOf('cxo2sskkbb') !== -1),
            lineEcharts:"",
            brokenData:{},
            pageNum:1,
            patientInfo:{}
        }
    },
     computed:{
		getters(){
			return this.$store.getters
		}
	},

    watch:{
        'getters':{
			handler(news,old){
                if(!this.$store.getters.selectPatientId&&this.$store.getters.patientData.length!=0){
                    this.patientInfo = this.$store.getters.patientData[0]
                }else{
                    this.patientInfo = this.$store.getters.patientData.find(item => item.id  == this.$store.getters.selectPatientId);
                }

			},
			deep:true
		},
        'patientInfo':{
            handler(){
                this.getBrokenData();
                this.getMonitorQuery();
            },
            deep:true
        }
    },
    async created(){
        //
        if (window.navigator.userAgent.indexOf('9999999') !== -1) {
            if(this.$route.query.patientId){
                this.btnShow = false;
            }
        }

        document.title = this.$t("bloodPressure.name");
        // new VConsole()
        if(this.tabAvtive  == 0){
            this.et = getLocalDateTime(new Date())
            this.st = getLocalDateTime(new Date().getTime() - 24 * 1000 * 3600 * 6)
        }
        await this.getPatientData();
        // console.log(this.patientInfo,this.$store.getters);
        // this.st = new Date().getFullYear() +"-"+((new Date().getMonth()+1)<10?'0'+(new Date().getMonth()+1):new Date().getMonth()+1)+'-'+((new Date().getDate()<10?'0'+new Date().getDate():new Date().getDate()));
        await this.getBrokenData();
        await this.getMonitorQuery();
    },
    async mounted(){

        // this.gettick();
        // window.addEventListener('resize', this.showDetailBtnFun);
        // this
    },
    methods:{
        gettick(){
            setTimeout(() => {
                this.$nextTick(()=>{
                    for(let i in this.$refs){
                        if(i.indexOf("desc")>-1){
                            if(this.$refs[i][0].clientHeight<this.$refs[i][0].scrollHeight){
                                this.monitorList[this.$refs[i][0].dataset.index].moreTxtShow = true;
                            }
                        }
                    }
                })
            }, 500);
        },
        // 获取就诊人数据
		getPatientData(){

			patientQuery({}).then(res=>{
				console.log(res);
				if(res.data.errorCode == 0){
					if(res.data.data.length !== 0){
						this.$store.commit('user/SET_PATIENTDATA',res.data.data);
					}
				}else{
					this.$Toast(this.$t("patient.patientfail"))
				}
			})
		},
        getBrokenData(){
            pressurStat({
                st:this.st,
                et:this.et,
                patientId:this.btnShow?this.$store.getters.selectPatientId:this.$route.query.patientId,
                cusId:this.cusId
            }).then(res=>{
                console.log(res.data);
                if(res.data.errorCode == 0){

                    this.brokenData = res.data.data;

                    this.EchartsInit();
                }else{
                    this.EchartsInit();
                }
            },err=>{
                this.EchartsInit();
            })
        },
        openSurveyor(){
            let index = this.$store.getters.patientData.findIndex(item => item.id  == this.$store.getters.selectPatientId);
            this.$store.commit("user/SET_PATIENTINDEX",index);
            this.$store.commit("user/SET_SENDPATIENT",true);
        },
        // 加载更多
        more(){
            // if(this.fin)
            if(!this.moreType){
                this.pageNum ++ ;
                this.getMonitorQuery();
            }
        },
        goAddPatient(){
            if(this.$store.getters.patientData.length==0||!this.patientInfo.id){
                return false;
            }
            this.$router.push({
                path:"/patient/add"
            })
        },
        goAppAdd(){
            if(this.$store.getters.patientData.length==0||!this.patientInfo.id){
                return false;
            }
            let isAndroid = navigator.userAgent.indexOf('Android') > -1 || navigator.userAgent.indexOf('Adr') > -1;
            let isiOS = !!navigator.userAgent.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/);
            if (isAndroid) {
                // window.android.JSToNative(JSON.stringify(params))
                window.native.xueyaji(this.patientInfo.id);
                return false;
            } else {
                // console.log(this.$store.getters);
                window.webkit.messageHandlers.xueyaji.postMessage(this.patientInfo.id.toString())
                return false;
                // window.webkit.messageHandlers.JSToNative.postMessage(params)
                // JSToNative(JSON.stringify(params))
            }
        },
        // 获取页面数据
        getMonitorQuery(){
            this.isLoading = true;
            pressurQuery({
                pageNum:this.pageNum,
                pageSize:10,
                st:this.st,
                et:this.et,
                patientId:this.btnShow?this.$store.getters.selectPatientId:this.$route.query.patientId,
                cusId:this.cusId
            }).then(res=>{
                if(res.data.errorCode == 0){
                    if(this.pageNum == 1){
                        this.monitorList = [];
                    }
                    res.data.data.data.map(item=>{
                        item.moreTxtShow = false;
                        item.moreOpen = false;
                        this.monitorList.push(item);
                    })
                    this.gettick();
                    if(res.data.data.data.length<10){
                        this.moreType = false;
                    }
                }
                this.isLoading = false;
            },err=>{
                this.isLoading = false;
            })
        },
        tabClick(index){
            if(index!=2){
                this.tabAvtive = index;
                if(this.tabAvtive  == 0){
                    this.et = getLocalDateTime(new Date())
                    this.st = getLocalDateTime(new Date().getTime() - 24 * 1000 * 3600 * 6)
                }else if(this.tabAvtive == 1){
                    this.et = getLocalDateTime(new Date())
                    this.st = getLocalDateTime(new Date().getTime() - 24 * 1000 * 3600 * 29)
                }
                this.pageNum = 1;
                this.getMonitorQuery()
                this.getBrokenData();
            }else{
                this.pickerDateShow = true;
                if(this.st){
                    this.currentDate = new Date(this.st);
                }
            }
        },
        // 页面滚动条监听
        scrollBar(e){
            if(e.target.scrollTop>this.$refs.listTitle.offsetTop){
                this.titleFiexd = true;
            }else{
                this.titleFiexd = false;
            };
        },
        setheight(item,type){
            if(type == 1){
                this.$refs['desc'+item.id][0].style.height = this.$refs['desc'+item.id][0].scrollHeight+'px';
            }else{
                this.$refs['desc'+item.id][0].style.height = '0.5rem';
            }
            item.moreOpen = !item.moreOpen;
        },
        GoAdd(){
            if(this.$store.getters.patientData.length==0){
                return false;
            }
            this.$router.push({
                path:"/bloodPressure/add"
            })
        },
        dateConfirm(e){
            this.yearType = true;
            // this.st = new Date(e).getFullYear() +"-"+((new Date(e).getMonth()+1)<10?'0'+(new Date(e).getMonth()+1):new Date(e).getMonth()+1)+'-'+((new Date(e).getDate()<10?'0'+new Date(e).getDate():new Date(e).getDate()));
            this.st = getLocalDateTime(e);
            let d = new Date(new Date(e).getFullYear(), new Date(e).getMonth()+1, 0).getDate();
            let ed = new Date(new Date(e).getTime() + (d-2) * 24 * 60 * 60 * 1000);
            // this.et =  ed.getFullYear()+"-"+((ed.getMonth()+1)<10?'0'+(ed.getMonth()+1):ed.getMonth()+1)+"-"+((ed.getDate())<10?'0'+(ed.getDate()+1):ed.getDate()+1)
            this.et = getLocalDateTime(new Date(new Date(e).getTime() + (d-2) * 24 * 60 * 60 * 1000));
            this.tabAvtive = 2;
            this.pickerDateShow = false;
            this.pageNum = 1;
            this.getBrokenData();
            this.getMonitorQuery();

        },
        EchartsInit(){
            this.lineEcharts = echarts.init(document.getElementById('echartsLine'));
            // console.log("12313");
            // this.brokenData.
            let xData = [],yData1 = [],yData2 = [];
            // Sys是收缩压，Dia是舒张压，
            if(this.brokenData){
                for(let i in this.brokenData){
                    xData.push(this.brokenData[i].date)
                    yData1.push(this.brokenData[i].dia)
                    yData2.push(this.brokenData[i].sys)
                }
            }

            let option = {
                tooltip: {
                    trigger: 'item',
                    axisPointer: { // 坐标轴指示器，坐标轴触发有效
                        trigger: 'item',
                        appendToBody:true,
                    }
                },
                grid: {
                    left: '5%',
                    right: '12%',
                    bottom: '20%',
                    top:"5%",
                    containLabel: true
                },

                // color:['#2db7f5','#ff6600','#808bc6'],
                legend: {
                    data: ['收缩压','舒张压'],
                    textStyle: { //图例文字的样式
                        color: '#FFFFFF',
                        fontSize: 12
                    },
                    bottom:"5%"
                },
                xAxis: [{
                    type: 'category',
                    data: xData,
                    name:"日期",
                    axisTick: {
                        alignWithLabel: true,
                        inside: true
                    },
                    axisLine: {
                        lineStyle: {
                            color: '#FFFFFF'
                        }
                    },
                    axisLabel: {
                        show: true,
                        color: '#FFFFFF',
                        fontSize: 12,
                        rotate:20,
                    },
                    offset:10,

                }],
                yAxis: [{
                        type: 'value',
                        name:"血压（mmHg）",
                        min:20,
                        splitLine: {
                            show: true,
                        },
                        axisTick: {
                            show: false
                        },
                        axisLine: {
                            show:true,
                            lineStyle: {
                                color: '#FFFFFF'
                            }
                        },
                        axisLabel: {
                            show:true,
                            color: '#FFFFFF',
                            fontSize:12,
                            formatter: '{value}'
                        }
                }],
                series: [
                    {
                        type: 'line',
                        smooth: true,
                        name:"收缩压",
                        data: yData2,
                        markLine:{

                            data:[
                                {
                                    yAxis:120,
                                    name:"标准线",

                                    lineStyle:{
                                        color:"#75E1FF"
                                    },
                                }
                            ]
                        },
                        symbol: 'circle',
                        symbolSize: 8,
                        itemStyle: {
                        normal: {
                            color: '#fff',
                            borderColor: 'rgba(59, 133, 250, 1)',
                            lineStyle: {
                            color: 'rgba(59, 133, 250, 1)'
                            }
                        }
                        }
                    },{
                        type: 'line',
                        smooth: true,
                        name:"舒张压",
                        data: yData1,
                        symbol: 'circle',
                        symbolSize: 8,
                        markLine:{
                            data:[
                                {
                                    yAxis:80,
                                    name:"标准线",
                                    lineStyle:{
                                        color:"#FFC56E"
                                    },
                                }
                            ],
                        },
                        itemStyle: {
                            normal: {
                                color: '#fff',
                                borderColor: 'rgba(253, 162, 56, 1)',
                                lineStyle: {
                                color: 'rgba(253, 162, 56, 1)'
                                }
                            }
                        }
                    }
                ]
            };
            // console.log("1234567");
            this.lineEcharts.setOption(option);
            this.lineEcharts.resize();

        }
    }
}
</script>
<style lang="less" >
.van-picker__title{
    font-size: 0.6rem;
    line-height: initial;
    height: max-content;
}
</style>
<style lang="less" scoped>
.monitor-main{
    display: flex;
    height: 100%;
    flex-direction: column;
    .main-container{
        flex: 1;
        overflow: auto;
        background-color: #FFFFFF;
        .container-echarts{
            height: 13.6rem;
            padding: 0.3rem 0.6rem 1rem 0.6rem;
            display: flex;
            box-sizing: border-box;
            background: linear-gradient(360deg, #3C8DFF 0%, #DBEEFC 100%);
            flex-direction: column;
            .patient-container{
                display: flex;
                align-items: center;
                position: relative;
                .switch-icon{
                    position: absolute;
                    right: -0.6rem;
                    width: 3rem;
                    height: 1rem;
                    background: #49A7FF;
                    border-radius: 1rem 0 0 1rem;
                    border: 0.02rem solid #FFFFFF;
                    top: 50%;
                    transform: translate(0, -50%);
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    img{
                        width: 0.52rem;
                        height: 0.48rem;
                        margin-right: 00.2rem;
                    }
                    .txt{
                        font-size: 0.56rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #FFFFFF;
                        line-height: 0.48rem;
                    }
                }
                .patient-info{
                    .name{
                        font-size: 0.64rem;
                        font-family: PingFangSC-Medium, PingFang SC;
                        font-weight: 600;
                        color: #333333;
                    }
                    .gender-icon{
                        width: 0.7rem;
                        height: 0.7rem;
                        margin: 0 0.3rem;
                        background: url("~@/assets/img/wan-icon.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    .gender-woman{

                    }
                    .age{
                        font-size: 0.52rem;
                        font-family: Helvetica;
                        color: #666666;
                        padding: 0.08rem 0.3rem;
                        border-radius: 00.6rem;
                        border: 0.02rem solid #666666;
                    }
                }
            }
            .patient-emtry{
                height: 1.6rem;
                background: #FFEEEA;
                border-radius: 0.16rem;
                border: 0.04rem dashed #FF1A2A;
                display: flex;
                align-items: center;
                justify-content: center;
                .txt{
                    font-size: 0.72rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FF1A2A;
                }
                img{
                    width: 0.8rem;
                    height: 0.8rem;
                    margin-right: 00.3rem;
                }

            }
            .echarts-tab{
                margin-top: 0.6rem;
                display: flex;
                margin-bottom: 0.3rem;
                flex: 0 0 auto;
                .tab-left,.tab-right{
                    flex: 1;
                    height: 1.2rem;
                    overflow: hidden;
                    border-radius: 0.6rem;
                    box-sizing: border-box;
                }
                .tab-right{
                    border: 0.02rem solid #298AFB;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    i{
                        width: 0.56rem;
                        height: 0.52rem;
                        background: url("~@/assets/img/date-icon.png") no-repeat;
                        background-size: 100% 100%;
                        margin-right: 0.24rem;
                    }
                    span{
                        font-size: 0.56rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #298AFB;
                    }
                }
                .tab-right.itemActive{
                    i{
                        background: url("~@/assets/img/date-cion-empty.png") no-repeat;
                        background-size: 100% 100%;
                    }
                    span{
                        color: #FFFFFF;
                    }
                }
                .tab-left{
                    // background: #FFFFFF;
                    border: 0.02rem solid #298AFB;
                    display: flex;
                    .tab-item{
                        flex: 1;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        font-size: 0.56rem;
                        font-family: PingFangSC-Regular, PingFang SC;
                        font-weight: 400;
                        color: #298AFB;
                        &:first-child{
                            border-right: 0.02rem solid #298AFB;
                        }
                    }
                }
                .tab-right{
                    margin-left: 0.4rem;
                }
                .itemActive{
                    color: #FFFFFF !important;
                    background-color: #298AFB !important;
                }
            }
            .echart-broken{
                flex: 1;
            }
        }
        .emtry{
            height: 0.6rem;
            background-color: #f7f7f7;
        }
        .container-list{
            position: relative;
            z-index: 1;
            border-radius: 0.52rem 0.52rem 0 0;
            margin-top: -1rem;
            padding: 0.4rem 0.6rem;
            background-color: #FFFFFF;
            .app-btn-container{
                padding: 1rem 0;
                display: flex;
                align-items: center;
                justify-content: center;
                .btn-item{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    height: 1.76rem;
                    border-radius: 1rem;
                    background: linear-gradient(180deg, #80D3FF 0%, #298AFB 100%);
                    margin-right: 0.76rem;
                    flex: 1;
                    font-size: 0.72rem;
                    font-family: PingFangSC-Medium, PingFang SC;
                    font-weight: 500;
                    color: #FFFFFF;
                    &:last-child{
                        margin-right: 0;
                        background: linear-gradient(180deg, #FFE37E 0%, #FF8742 100%);
                    }
                }
            }
            .disabledbtn{
                .btn-item{
                    background: #DCDFE6 !important;
                }
            }
            .list-title,.title-empty{
                font-size: 0.68rem;
                font-family: PingFangSC-Medium, PingFang SC;
                font-weight: 500;
                color: #181818;
                padding-left: 0.52rem;
                position: relative;
                line-height: 0.96rem;
                &::after{
                    content: " ";
                    position: absolute;
                    top: 50%;
                    transform: translate(0,-50%);
                    border-radius: 0.1rem;
                    left: 0;
                    width: 0.2rem;
                    max-height: 0.6rem;
                    height: 80%;
                    background: #298AFB;
                }
            }
            .title-empty{
                opacity: 0;
                position: absolute;
            }

            .fiexd-title{
                position: fixed;
                opacity: 1;
                top: 0;
                width: 100%;
                padding:0.2rem 0.6rem;
                background-color: #FFFFFF;
                z-index: 9;
            }
            .list-main{
                padding: 0 0.6rem;
                margin-top: 0.4rem;
                .list-item{
                    margin-bottom: 0.8rem;
                    overflow: hidden;
                    .item-time{
                        display: flex;
                        align-items: center;
                        span{
                            font-size: 0.56rem;
                            font-family: PingFangSC-Medium, PingFang SC;
                            font-weight: 500;
                            color: #9B9B9B;
                            line-height: 0.8rem;
                            flex: 1;
                        }
                        i{
                            width: 0.52rem;
                            height: 0.56rem;
                            margin-right: 0.1rem;
                            flex: 0 0 auto;
                            background: url("~@/assets/img/blood-pressure-time-icon.png") no-repeat;
                            background-size: 100% 100%;
                        }
                    }
                    .item-info{
                        border-radius: 0.32rem;
                        overflow: hidden;
                        margin-top: 0.3rem;
                        .info-details{
                            background: #F7F8FD;
                            display: flex;
                            justify-content: space-between;
                            padding:0.6rem;
                            .details-item{
                                flex: 1;
                                display: flex;
                                height: 2rem;
                                flex-direction: column;
                                align-items: center;
                                justify-content: flex-start;
                                .title{
                                    font-size: 0.52rem;
                                    font-family: PingFangSC-Regular, PingFang SC;
                                    font-weight: 400;
                                    color: #181818;
                                    height: 1.2rem;
                                    line-height: 1.2rem;
                                }
                                .txtValue{
                                    font-size: 0.68rem;
                                    font-family: PingFangSC-Semibold, PingFang SC;
                                    font-weight: 600;
                                    color: #298AFB;
                                    margin-bottom: 0.2rem;
                                    line-height: 1.12rem;
                                }
                                .txtType{
                                    font-size: 0.56rem;
                                    font-family: PingFangSC-Medium, PingFang SC;
                                    font-weight: 500;
                                    line-height: 1.12rem;
                                    color: #181818
                                }
                            }

                        }
                        .item-desc{
                            background: #EBF1FD;
                            padding: 0.2rem 0.6rem;
                            position: relative;
                            p{
                                font-size: 0.52rem;
                                font-family: PingFangSC-Light, PingFang SC;
                                font-weight: 300;
                                color: #9B9B9B;
                                overflow: hidden;
                                // text-overflow: ellipsis;
                                // white-space: nowrap;
                                height: 0.8rem;
                                transition: all 0.3s linear 0s;
                            }
                            .moreTxt{
                                // white-space: normal;
                                height: auto;
                            }
                            .packup{
                                color: #298AFB;
                                text-align: center;
                                padding-top: 0.2rem;
                            }
                            .unfold{
                                color: #298AFB;
                                position: absolute;
                                font-size: 0.6rem;
                                right: 0.2rem;
                                top: 50%;
                                transform: translate(0,-50%);
                            }
                        }
                    }
                }
                .moreData{
                    font-size: 0.56rem;
                    text-align: center;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9B9B9B;
                }
            }
            .list-empty{
                // height: calc(100vh - 35rem);
                display: flex;
                flex-direction: column;
                margin: 1.6rem auto;
                justify-content: center;
                align-items: center;
                img{
                    width: 6rem;
                    height: 4rem;
                }
                .txt{
                    font-size: 0.56rem;
                    font-family: PingFangSC-Regular, PingFang SC;
                    font-weight: 400;
                    color: #9B9B9B;
                    line-height: 0.8rem;
                    margin: 0.7rem 0;
                }
                .btn{
                    width: 8.6rem;
                    height: 1.8rem;
                    background: #298AFB;
                    color: #FFFFFF;
                    font-size: 0.64rem;
                    border-radius: 0.9rem;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
    .main-bottom-btn{
        flex: 0 0 auto;
        height: 2rem;
        background: #298AFB;
        font-size: 0.68rem;
        display: flex;
        align-items: center;
        justify-content: center;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #FFFFFF;
    }
}
</style>
