<template>
    <div class="mask">
        <div class="loading">
            <img src="../../assets/loading.gif" class="loading-icon"/>
            <div v-if="msg" v-html="msg"></div>
        </div>
    </div>
</template>
<script>
export default {
  props: {
    msg: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
    .loading {
        min-width: 3rem;
        min-height: 2.4rem;
        text-align: center;
        position: fixed;
        top: 40%;
        left: 50%;
        z-index: 1000;
        background: rgba(0, 0, 0, 0.6);
        color: #fff;
        font-size: 0.6rem;
        margin-top: -1.5rem;
        -webkit-transition: opacity 1s;
        transition: opacity 1s;
        box-sizing: border-box;
        border-radius: 0.3rem;
        padding: 0 0.5rem 0.3rem;
        -webkit-transform: translate(-50%, 0);
    }

    .loading-icon {
        display: block;
        width: 2.2rem;
        margin: 0 auto;
    }

    .loading > div {
        position: relative;
        margin-top: -0.3rem;
        white-space: nowrap;
        padding-bottom: 0.2rem;

    }
  .mask{
    background: #fff;
    opacity: 0.9;
  }
</style>
